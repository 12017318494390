import React, { useState } from "react";
import staticImg from "../../assets/static.png";
import internshipImg from "../../assets/personalized_website.png";
import form from "../../assets/personalized_form.png";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Item = () => {
  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [requirement, setRequirement] = useState("");

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const userDocRef = collection(db, "personalized-website-services");
      const newInternship = {
        firstName: firstname,
        lastName: lastName,
        email: email,
        phone: phone,
        companyName: companyName,
        companyAddress: companyAddress,
        requirement: requirement,
      };
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setCompanyName("");
      setCompanyAddress("");
      setRequirement("");
      await addDoc(userDocRef, newInternship);
      toast.success("Submitted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <ToastContainer 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <main className=" relative overflow-hidden">
        <div className="  flex relative z-20 items-center overflow-hidden">
          <div className="container mx-auto px-6 flex relative py-16">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
              <span className="w-20 h-2 bg-gray-800  mb-12"></span>
              <h1 className="font-bebas-neue uppercase text-4xl sm:text-6xl font-black flex flex-col leading-none  text-gray-800">
                Personalized
                <span className="text-5xl sm:text-7xl">Static Websites</span>
              </h1>
              <p className="text-sm mt-5 sm:text-base text-gray-700 ">
                Elevate your online presence with Intellectic's tailored website
                building solutions. Our team of expert designers and developers
                are dedicated to crafting stunning, user-friendly websites that
                perfectly reflect your unique vision and business goals.
              </p>
            </div>
            <div className="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
              <img src={staticImg} className=" m-auto lg:ml-10" />
            </div>
          </div>
        </div>
      </main>

      {/* price */}
      <div className="flex justify-center">
        <h1 className="font-bebas-neue mb-10 uppercase text-4xl sm:text-6xl font-black flex flex-col leading-none  text-gray-800">
          Our Packages
        </h1>
      </div>

      <div className="flex justify-center px-8 gap-20 flex-wrap text-zinc-800 mt-10 mb-20">
        <div className="flex flex-col w-96 items-center bg-slate-100 p-8 rounded-lg shadow-2xl max-w-sm ">
          <div>
            <h2 className="font-extrabold text-3xl text-center mb-2">
              Starter Pack
            </h2>
            <p className="opacity-60 text-center">For Growing Startups</p>
            <div className="flex flex-col items-center my-8">
              <p className="font-extrabold text-4xl">&#8377;4999</p>
              <p className="text-sm opacity-60">+ gst</p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Trending Landing Page</b>
            </p>
           
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>5 to 7 Pages Website</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Free Maintenance for 1 month</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>SEO Friendly</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>User Authentication</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Mobile Responsive</b>
            </p>
           
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Social Media Integration</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>{" "}
              24/7 online support
            </p>
            <div className="flex justify-center mt-8"></div>
          </div>
        </div>
        <div className="flex flex-col w-96 items-center  p-8 rounded-lg shadow-lg relative border-8 border-orange-200 max-w-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
            className="w-20 h-20 absolute -top-11 -left-11 fill-red-400"
          >
            <path
              fillRule="evenodd"
              d="M12.963 2.286a.75.75 0 00-1.071-.136 9.742 9.742 0 00-3.539 6.177A7.547 7.547 0 016.648 6.61a.75.75 0 00-1.152-.082A9 9 0 1015.68 4.534a7.46 7.46 0 01-2.717-2.248zM15.75 14.25a3.75 3.75 0 11-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 011.925-3.545 3.75 3.75 0 013.255 3.717z"
              clipRule="evenodd"
            />
          </svg>
          <p className="mono text-sm absolute -top-4 bg-red-400 text-zinc-100 py-0.5 px-2 font-bold tracking-wider rounded">
            POPULAR
          </p>
          <div>
            <div className="flex gap-4 justify-center">
              <p className="font-extrabold text-3xl mb-2">Pro Pack</p>
            </div>
            <p className="opacity-60 text-center">
              For startups, agencies and businesses
            </p>
            <p className="opacity-60 text-center"></p>
            <div className="flex gap-4 justify-center">
              <div className="flex flex-col items-center my-8">
                <p className="font-extrabold text-4xl">&#8377;6999</p>
                <p className="text-sm opacity-60">+ gst</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Trending Landing Page</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>5 Official Work Email</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>upto 10 Pages Website</b>
            </p>
           
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>User Authentication</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Free Maintenance (Upto 2 months)</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>SEO Friendly</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Mobile Responsive</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Free Logo Designing</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Free Hosting (For 2 months)</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Social Media Integration</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>{" "}
              24/7 online support
            </p>
            <div className="flex justify-center mt-8 "></div>
          </div>
        </div>
      </div>

      {/* price */}

      {/* Timeline */}

      <section>
        <div className=" text-white py-8">
          <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
            <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
              <p className="text-3xl text-black md:text-4xl font-bold leading-normal mb-2">
                Here's how we operates
              </p>
              <p className="text-sm md:text-base text-gray-700 mb-4">
                At Intellectic Software Solution, we understand that your online
                presence is a reflection of your unique brand and identity.
                That's why we take pride in offering personalized websites
                tailored to your specific needs and vision. Our expert team of
                designers and developers work closely with you to create a
                stunning and functional website that not only captures the
                essence of your business but also delivers an exceptional user
                experience.
              </p>
            </div>
            <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
              <div className="container mx-auto w-full h-full">
                <div className="relative wrap overflow-hidden p-10 h-full">
                  <div
                    className="border-2-2 border-gray-700 absolute h-full border"
                    style={{
                      right: "50%",
                      border: "2px solid black",
                      borderRadius: "1%",
                    }}
                  />
                  <div
                    className="border-2-2 border-gray-700 absolute h-full border"
                    style={{
                      left: "50%",
                      border: "2px solid black",
                      borderRadius: "1%",
                    }}
                  />
                  <div className="lg:mb-8  flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-4 text-right">
                      <h4 className="mb-3 font-bold text-black text-lg md:text-2xl">
                        Form Filling
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-gray-600 text-opacity-100">
                        Please complete a form that includes personal details
                        and specify your website requirements.
                      </p>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1  w-5/12 px-1 py-4 text-left">
                      <h4 className="mb-3 font-bold text-black text-lg md:text-2xl">
                        Consultation
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-gray-600 text-opacity-100">
                        Upon receiving your request, our support team will get
                        in touch with you to discuss about your project.
                      </p>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-4 text-right">
                      <h4 className="mb-3 font-bold text-black text-lg md:text-2xl">
                        Payment
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-gray-600 text-opacity-100">
                        After a successful consultation, you will be required to
                        make payment of 50% of total amount and other 50% after
                        completing the project.
                      </p>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1  w-5/12 px-1 py-4">
                      <h4 className="mb-3 font-bold text-black  text-lg md:text-2xl text-left">
                        Delivery
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-gray-600 text-opacity-100">
                        Within 12 days of your registration with us, we will
                        deliver your website. Additionally, we will provide you
                        with 100 visiting cards of your company and ensure their
                        delivery to the address you've provided us, all as part
                        of our service.
                      </p>
                    </div>
                  </div>
                </div>
                <img className="mx-auto mt-2" src={internshipImg} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Timeline */}

      {/* Form */}

      <div className="flex justify-center items-center place-content-center mb-20">
        <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
          Consultation Form
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-8/12 bg-white rounded-xl mx-auto shadow-xl overflow-hidden">
          <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-12 bg-no-repeat bg-cover bg-center">
            <div>
              <img
                src={form}
                className="lg:h-[300px] h-[200px] w-full object-cover "
                alt=""
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 py-8 px-8 lg:px-6">
            <h2 className="text-2xl mb-4"> Personalized Website Services</h2>
            <p className="mb-4">Choose a service of your interest below.</p>
            <form>
              <div className="grid grid-cols-2 gap-5">
                <input
                  type="text"
                  placeholder="First Name"
                  className="border border-gray-400 py-1 px-2"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="border border-gray-400 py-1 px-2"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                />
                <input
                  type="number"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                />
              </div>
              <div className=" mt-5">
                <input
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                />
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  placeholder="Company Address"
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                />
              </div>

              <div className=" mt-5">
                <textarea
                  rows={5}
                  type="text"
                  placeholder="How can we help? Tell us what features you are looking for."
                  value={requirement}
                  onChange={(e) => setRequirement(e.target.value)}
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                />
              </div>

              <div className="mt-5">
                <button
                  className="w-full bg-cyan-800 py-3 text-center text-white"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Form */}

      {/* Way to choose us */}

      <section className="py-12  text-gray-100 sm:py-12 lg:py-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <h1 className="font-bebas-neue mb-10 uppercase text-4xl sm:text-6xl font-black flex flex-col leading-none  text-gray-800">
              Why to choose us
            </h1>
          </div>
          <div className="grid max-w-4xl lg:max-w-6xl grid-cols-1 mx-auto mt-8 text-center gap-y-4 sm:gap-x-8 sm:grid-cols-2 lg:grid-cols-3 sm:mt-12 lg:mt-20 sm:text-left">
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white shadow-md transition-transform transform hover:-translate-y-2 rounded-xl h-full">
                <div className="p-9">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 8L20 8"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M4 16L14 16"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={7}
                      cy={8}
                      rx={3}
                      ry={3}
                      transform="rotate(90 7 8)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={17}
                      cy={16}
                      rx={3}
                      ry={3}
                      transform="rotate(90 17 16)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                    Tailored Design Expertise
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                    We specialize in creating static personalized websites that
                    are uniquely crafted to suit your brand identity and
                    business goals. Our team of experienced designers pays
                    meticulous attention to detail, ensuring that your website
                    stands out in the crowded online space.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
                <svg
                  className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x={13}
                    y={14}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={7}
                    y={11}
                    width={2}
                    height={6}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={11}
                    y={13}
                    width={2}
                    height={6}
                    rx={1}
                    transform="rotate(90 11 13)"
                    fill="#111827"
                  ></rect>
                  <rect
                    x={16}
                    y={12}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <path
                    d="M14 8V8C14 7.58326 14 7.37488 13.9655 7.19144C13.8455 6.5546 13.4245 6.01534 12.8358 5.74455C12.6662 5.66654 12.464 5.616 12.0597 5.51493L12 5.5C11.5388 5.3847 11.3082 5.32706 11.1171 5.233C10.5686 4.96315 10.1737 4.45731 10.0449 3.85979C10 3.65151 10 3.41382 10 2.93845V2"
                    stroke="#111827"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                  <path
                    d="M3 14C3 11.4412 3 10.1618 3.61994 9.28042C3.77954 9.05351 3.96572 8.85041 4.17372 8.6763C4.98164 8 6.15442 8 8.5 8H15.5C17.8456 8 19.0184 8 19.8263 8.6763C20.0343 8.85041 20.2205 9.05351 20.3801 9.28042C21 10.1618 21 11.4412 21 14C21 16.5588 21 17.8382 20.3801 18.7196C20.2205 18.9465 20.0343 19.1496 19.8263 19.3237C19.0184 20 17.8456 20 15.5 20H8.5C6.15442 20 4.98164 20 4.17372 19.3237C3.96572 19.1496 3.77954 18.9465 3.61994 18.7196C3 17.8382 3 16.5588 3 14Z"
                    stroke="#111827"
                    strokeWidth={2}
                  />
                </svg>
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Exceptional User Experience
                </h3>
                <p className="mt-6 text-base text-gray-600">
                  We prioritize user-centric design, guaranteeing that your
                  website is not only visually appealing but also intuitive and
                  easy to navigate. A seamless user experience translates to
                  increased engagement and higher conversion rates.
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl h-full">
                <div className="p-9">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 8L20 8"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M4 16L14 16"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={7}
                      cy={8}
                      rx={3}
                      ry={3}
                      transform="rotate(90 7 8)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={17}
                      cy={16}
                      rx={3}
                      ry={3}
                      transform="rotate(90 17 16)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                    Performance and Speed
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                    Our static websites are optimized for performance and speed.
                    With fast loading times and efficient coding, your site will
                    deliver a superior experience to visitors, leading to better
                    search engine rankings and satisfied users.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
                <svg
                  className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x={13}
                    y={14}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={7}
                    y={11}
                    width={2}
                    height={6}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={11}
                    y={13}
                    width={2}
                    height={6}
                    rx={1}
                    transform="rotate(90 11 13)"
                    fill="#111827"
                  ></rect>
                  <rect
                    x={16}
                    y={12}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <path
                    d="M14 8V8C14 7.58326 14 7.37488 13.9655 7.19144C13.8455 6.5546 13.4245 6.01534 12.8358 5.74455C12.6662 5.66654 12.464 5.616 12.0597 5.51493L12 5.5C11.5388 5.3847 11.3082 5.32706 11.1171 5.233C10.5686 4.96315 10.1737 4.45731 10.0449 3.85979C10 3.65151 10 3.41382 10 2.93845V2"
                    stroke="#111827"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                  <path
                    d="M3 14C3 11.4412 3 10.1618 3.61994 9.28042C3.77954 9.05351 3.96572 8.85041 4.17372 8.6763C4.98164 8 6.15442 8 8.5 8H15.5C17.8456 8 19.0184 8 19.8263 8.6763C20.0343 8.85041 20.2205 9.05351 20.3801 9.28042C21 10.1618 21 11.4412 21 14C21 16.5588 21 17.8382 20.3801 18.7196C20.2205 18.9465 20.0343 19.1496 19.8263 19.3237C19.0184 20 17.8456 20 15.5 20H8.5C6.15442 20 4.98164 20 4.17372 19.3237C3.96572 19.1496 3.77954 18.9465 3.61994 18.7196C3 17.8382 3 16.5588 3 14Z"
                    stroke="#111827"
                    strokeWidth={2}
                  />
                </svg>
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Affordability and Cost Efficiency
                </h3>
                <p className="mt-6 text-base text-gray-600">
                  We understand the importance of a cost-effective solution for
                  businesses. Our static website packages are competitively
                  priced, allowing you to achieve a professional online presence
                  without breaking the bank.
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl h-full">
                <div className="p-9">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 8L20 8"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M4 16L14 16"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={7}
                      cy={8}
                      rx={3}
                      ry={3}
                      transform="rotate(90 7 8)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={17}
                      cy={16}
                      rx={3}
                      ry={3}
                      transform="rotate(90 17 16)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                    Ongoing Support and Maintenance
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                    Our commitment to your success doesn't end with website
                    deployment. We offer comprehensive support and maintenance
                    services to ensure your website remains up-to-date, secure,
                    and fully functional. You can count on us to be your
                    reliable partner in the long run.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
                <svg
                  className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x={13}
                    y={14}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={7}
                    y={11}
                    width={2}
                    height={6}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={11}
                    y={13}
                    width={2}
                    height={6}
                    rx={1}
                    transform="rotate(90 11 13)"
                    fill="#111827"
                  ></rect>
                  <rect
                    x={16}
                    y={12}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <path
                    d="M14 8V8C14 7.58326 14 7.37488 13.9655 7.19144C13.8455 6.5546 13.4245 6.01534 12.8358 5.74455C12.6662 5.66654 12.464 5.616 12.0597 5.51493L12 5.5C11.5388 5.3847 11.3082 5.32706 11.1171 5.233C10.5686 4.96315 10.1737 4.45731 10.0449 3.85979C10 3.65151 10 3.41382 10 2.93845V2"
                    stroke="#111827"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                  <path
                    d="M3 14C3 11.4412 3 10.1618 3.61994 9.28042C3.77954 9.05351 3.96572 8.85041 4.17372 8.6763C4.98164 8 6.15442 8 8.5 8H15.5C17.8456 8 19.0184 8 19.8263 8.6763C20.0343 8.85041 20.2205 9.05351 20.3801 9.28042C21 10.1618 21 11.4412 21 14C21 16.5588 21 17.8382 20.3801 18.7196C20.2205 18.9465 20.0343 19.1496 19.8263 19.3237C19.0184 20 17.8456 20 15.5 20H8.5C6.15442 20 4.98164 20 4.17372 19.3237C3.96572 19.1496 3.77954 18.9465 3.61994 18.7196C3 17.8382 3 16.5588 3 14Z"
                    stroke="#111827"
                    strokeWidth={2}
                  />
                </svg>
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Extremely fast
                </h3>
                <p className="mt-6 text-base text-gray-600">
                  Experience the power of extreme speed that accelerates your
                  journey with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Way to choose us */}
    </>
  );
};

export default Item;
