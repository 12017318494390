import React from 'react'
import Item from '../components/personalized/Item'

const Personalized = () => {
  return (
    <div>
       <Item/>
    </div>
  )
}

export default Personalized