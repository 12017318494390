import React, { useState } from "react";
import RegImg from "../../assets/registration.png";
import { Select, Option, Checkbox } from "@material-tailwind/react";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { List, ListItem } from "@material-tailwind/react";



const Form = () => {
  const [selectedTechnology, setSelectedTechnology] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [stream, setStream] = useState("")
  const [branch, setBranch] = useState("")
  const [college, setCollege] = useState("")
  const [terms, setTerms] = useState(false)
  const handleTechnologyChange = (e) => {
    setSelectedTechnology(e);
  };
  const handleCheckboxChange = () => {
    setTerms(!terms); 
  };
  const handleProjectChange = (e) => {
    setSelectedProject(e);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDocRef = collection(db, "internships");
      const newInternship = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        stream: stream,
        branch:branch,
        college: college,
        selectedTechnology: selectedTechnology,
        selectedProject: selectedProject,
        terms: terms,
      };
      setFirstName('');
    setLastName('');
    setEmail('');
    setBranch('')
    setCollege('')
    setStream('')
    setTerms(false)
    setSelectedTechnology('');
    setSelectedProject('');

      await addDoc(userDocRef, newInternship);
      toast.success("Registered Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className=" ">
      <ToastContainer 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <div className="flex justify-center items-center place-content-center mb-20">
        <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
          Registeration Form
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-8/12 bg-white rounded-xl mx-auto shadow-lg overflow-hidden">
          <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-12 bg-no-repeat bg-cover bg-center">
            <div>
              <img
                src={RegImg}
                className="lg:h-[300px] h-[200px] w-full object-cover "
                alt=""
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 py-8 px-8 lg:px-6">
            <h2 className="text-3xl mb-4"> Intellectic Internship Programs</h2>
            <p className="mb-4">Choose a domain for your internship program.</p>
            <form>
              <div className="grid grid-cols-2 gap-5">
                <input
                  type="text"
                  placeholder="First Name"
                  className="border border-gray-400 py-1 px-2"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="border border-gray-400 py-1 px-2"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="mt-5">
                <input
                  type="email"
                  placeholder="Email"
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <input
                  type="text"
                  placeholder="Stream"
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                  value={stream}
                  onChange={(e) => setStream(e.target.value)}
                />
                 <input
                  type="text"
                  placeholder="Branch"
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                />
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  placeholder="University/College"
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                  value={college}
                  onChange={(e) => setCollege(e.target.value)}
                />
              </div>
             
              <div className="mt-5">
                <Select
                  label="Select Technology"
                  onChange={handleTechnologyChange}
                  animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                  }}
                  required
                >
                  <Option value="Frontend Development">
                    Frontend Development
                  </Option>
                  <Option value="Full-stack Development">
                    Full-stack Development
                  </Option>
                  <Option value="Android Development">
                    Android Development
                  </Option>
                  <Option value="Java Development">Java Development</Option>
                  <Option value="Python Development">Python Development</Option>
                  <Option value="Data Science">Data Science</Option>
                </Select>
              </div>
              <div className="mt-5">
                <Checkbox id="ripple-on" ripple={true} label="I accept all terms and condition provided by Intellectic" checked={terms} onChange={handleCheckboxChange}/>
                
              </div>
              {selectedTechnology === "Frontend Development" && (
                <div className="mt-5">
                  <Select
                    label="Select Project"
                    onChange={handleProjectChange}
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                    required
                  >
                    <Option value="Product Landing Page Application">
                      Product Landing Page Application
                    </Option>
                    <Option value="Calculator Application">
                      Calculator Application
                    </Option>
                    <Option value="Javascript Quiz Application">
                      Javascript Quiz Application
                    </Option>
                    <Option value="Portfolio Application">
                      Portfolio Application
                    </Option>
                    <Option value="Weather Application">
                      Weather Application
                    </Option>
                    <Option value="To-do Application">To-do Application</Option>
                  </Select>
                </div>
              )}
              {selectedTechnology === "Full-stack Development" && (
                <div className="mt-5">
                  <Select
                    label="Select Project"
                    onChange={handleProjectChange}
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                  >
                    <Option value="Blog Website Application">
                      Blog Website Application
                    </Option>
                    <Option value="Chat Application">Chat Application</Option>
                    <Option value="Food Delivery Application">
                      Food Delivery Application
                    </Option>
                    <Option value="E-commerce Application">
                      E-commerce Application
                    </Option>
                    <Option value="Video Conferenceing Application">
                      Video Conferenceing Application
                    </Option>
                    <Option value="Social Media Application">
                      Social Media Application
                    </Option>
                  </Select>
                </div>
              )}
              {selectedTechnology === "Android Development" && (
                <div className="mt-5">
                  <Select
                    label="Select Project"
                    onChange={handleProjectChange}
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                  >
                    <Option value="Tic Tac Toe Application">
                      Tic Tac Toe Application
                    </Option>
                    <Option value="News Application">News Application</Option>
                    <Option value="Music Application">Music Application</Option>
                    <Option value="Tuition Notes Application">
                      Tuition Notes Application
                    </Option>
                    <Option value="Online Voting Application">
                      Online Voting Application
                    </Option>
                    <Option value="Photo Management Application">
                      Photo Management Application
                    </Option>
                  </Select>
                </div>
              )}
              {selectedTechnology === "Java Development" && (
                <div className="mt-5">
                  <Select
                    label="Select Project"
                    onChange={handleProjectChange}
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                  >
                    <Option value="Password Generator Application">
                      Password Generator Application
                    </Option>
                    <Option value="Online Survey Application">
                      Online Survey Application
                    </Option>
                    <Option value="Temperature Conversion Program">
                      Temperature Conversion Program
                    </Option>
                    <Option value="Number Guessing Game">
                      Number Guessing Game
                    </Option>
                    <Option value="Electricity Billing Application">
                      Electricity Billing Application
                    </Option>
                    <Option value="Airline Reservation System">
                      Airline Reservation System
                    </Option>
                    <Option value="Library Management System">
                      Library Management System
                    </Option>
                  </Select>
                </div>
              )}
              {selectedTechnology === "Python Development" && (
                <div className="mt-5">
                  <Select
                    label="Select Project"
                    onChange={handleProjectChange}
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                  >
                    <Option value="Email Slicer Application">
                      Email Slicer Application
                    </Option>
                    <Option value="Snake Game Application">
                      Snake Game Application
                    </Option>
                    <Option value="Website Blocker Program">
                      Website Blocker Program
                    </Option>
                    <Option value="Number Guessing Game">
                      Number Guessing Game
                    </Option>
                    <Option value="Dice Rolling Simulator">
                      Dice Rolling Simulator
                    </Option>
                    <Option value="Fibonacci Generator Program">
                      Fibonacci Generator Program
                    </Option>
                    <Option value="Voice Assistant">Voice Assistant</Option>
                  </Select>
                </div>
              )}
              {selectedTechnology === "Data Science" && (
                <div className="mt-5">
                  <Select
                    label="Select Project"
                    onChange={handleProjectChange}
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                  >
                    <Option value="Fake News Detection">
                      Fake News Detection
                    </Option>
                    <Option value="Detection of Road Lane Lines">
                      Detection of Road Lane Lines{" "}
                    </Option>
                    <Option value="Sentimental Analysis">
                      Sentimental Analysis
                    </Option>
                    <Option value="Classifying breast cancer">
                      Classifying breast cancer
                    </Option>
                    <Option value="Recommender systems">
                      Recommender systems
                    </Option>
                    <Option value="Gender detection and age detection">
                      Gender detection and age detection
                    </Option>
                  </Select>
                </div>
              )}

              <div className="mt-5">
                <button
                  className="w-full bg-cyan-800 py-3 text-center text-white"
                  onClick={handleSubmit}
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className=" mt-20 mb-20">
        <div className="flex justify-center items-center place-content-center">
        <h1 className="text-xl mt-10 lg:mt-0 lg:text-2xl text-gray-800 font-extrabold mx-auto md:text-5xl">
         Terms and Condition for Internships
        </h1>
        </div>
       
        <div className="flex justify-center items-center">
        
      <List>
        <ListItem>1. Interns can do only one internship at a time.</ListItem>
        <ListItem>2. Once you have joined the internship, you are supposed to complete it so that you will be able to take full advantage of this experience.</ListItem>
        <ListItem>3. There is a restriction on the completion of the internship. Therefore, you need to complete it in 1 month.</ListItem>
        <ListItem>4. All codes you have to submit as per the given guidelines in the project submission part.</ListItem>
        <ListItem>5. You can communicate with us for any feedback, queries and concerns on our mail id &nbsp;support@intellectic.in. We take 24 hrs of time to respond.</ListItem>
        <ListItem>6. After successfully verifying the GitHub code, our team will send you your certificate and Letters of Recommendation (LORs). </ListItem>
        <ListItem>7. You will receive your documents approximately 5-6 days after the completion of the internship duration.</ListItem>
      </List>
      
        </div>
      </div>
    </div>
  );
};

export default Form;
