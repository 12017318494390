import React, { useState } from "react";
import staticImg from "../assets/resumeService.png";
import internshipImg from "../assets/resume.png";
import { FiUser,FiTool,FiThumbsUp,FiWatch,FiTruck } from "react-icons/fi";
import ResumeForm from "../assets/resume_form.png"
import { Select, Option } from "@material-tailwind/react";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResumeBuilding = () => {
  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resumeType, setResumeType] = useState("")
  const [experience, setExperienceType] = useState("")
  const handleResumeType = (e) => {
    setResumeType(e);
  };
  const handleExperience = (e) => {
    setExperienceType(e)
  };

const handleSubmit = async(e) =>{
  e.preventDefault()
  try {
    const userDocRef = collection(db, "resume-writing-services");
      const newInternship = {
        firstName: firstname,
        lastName: lastName,
        email: email,
        phone: phone,
        resumeType: resumeType,
        experience:experience,
      };
      setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('')
    setResumeType('')
    setExperienceType('')

      await addDoc(userDocRef, newInternship);
      toast.success("Submitted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
  } catch (error) {
    console.log(error)
  }


}


  return (
    <>
     <ToastContainer 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      <main className=" relative overflow-hidden">
        <div className="  flex relative z-20 items-center overflow-hidden">
          <div className="container mx-auto px-6 flex relative py-16">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
              <span className="w-20 h-2 bg-gray-800  mb-12"></span>
              <h1 className="font-bebas-neue uppercase text-4xl sm:text-6xl font-black flex flex-col leading-none  text-gray-800">
                Resume
                <span className="text-5xl sm:text-7xl">Building Services</span>
              </h1>
              <p className="text-sm mt-5 sm:text-base text-gray-700 ">
                Our company specializes in providing top-notch resume building
                services. We craft tailored resumes that showcase your skills
                and experience effectively. Our expert writers understand
                industry trends and applicant tracking systems, ensuring your
                resume stands out to employers. Elevate your career prospects
                with our professional resume services.
              </p>
            </div>
            <div className="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
              <img src={staticImg} className=" m-auto lg:ml-10" />
            </div>
          </div>
        </div>
      </main>

      {/* price */}
      <div className="flex justify-center">
        <h1 className="font-bebas-neue mb-10 uppercase text-4xl sm:text-6xl font-black flex flex-col leading-none  text-gray-800">
          Our Packages
        </h1>
      </div>

      <div className="flex justify-center px-8 gap-20 flex-wrap text-zinc-800 mt-10 mb-20">
        <div className="flex flex-col w-96 items-center  p-8 rounded-lg shadow-xl relative border-8 border-cyan-500 max-w-sm">
          <div>
            <div className="flex gap-4 justify-center">
              <p className="font-extrabold text-3xl mb-2">Beginner level</p>
            </div>
            <p className="opacity-60 text-center">For 0 - 3 years experience</p>
            <p className="opacity-60 text-center">
              <span>Delivery time -</span> 10 days
            </p>
            <p className="opacity-60 text-center"></p>
            <div className="flex gap-4 justify-center">
              <div className="flex flex-col items-center my-8">
                <p className="font-extrabold text-4xl">&#8377;1999</p>
                <p className="text-sm opacity-60">+ gst</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Make your CV accessible to a wide range of recruiters.</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>
                Capture recruiters' attention with a well-crafted, professional
                resume.
              </b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>
                Distinguish yourself as the ideal candidate for the position.
              </b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>
                Showcase the skills that recruiters in your industry highly
                value.
              </b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Utilize an appealing and recruiter-friendly resume layout.</b>
            </p>

            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Achieve an error-free resume.</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>ATS Optimized</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Access responsive customer support services.</b>
            </p>

            
          </div>
        </div>

        <div className="flex flex-col w-96 items-center  p-8 rounded-lg shadow-xl relative border-8 border-cyan-500 max-w-sm">
          <div>
            <div className="flex gap-4 justify-center">
              <p className="font-extrabold text-3xl mb-2">Intermediate level</p>
            </div>
            <p className="opacity-60 text-center">For 3 - 8 years experience</p>
            <p className="opacity-60 text-center">Delivery Time - 12 days</p>
            <p className="opacity-60 text-center"></p>
            <div className="flex gap-4 justify-center">
              <div className="flex flex-col items-center my-8">
                <p className="font-extrabold text-4xl">&#8377;3999</p>
                <p className="text-sm opacity-60">+ gst</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Make your CV accessible to a wide range of recruiters.</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>
                Capture recruiters' attention with a well-crafted, professional
                resume.
              </b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>
                Distinguish yourself as the ideal candidate for the position.
              </b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>
                Showcase the skills that recruiters in your industry highly
                value.
              </b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>ATS Optimized</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Utilize an appealing and recruiter-friendly resume layout.</b>
            </p>

            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Achieve an error-free resume.</b>
            </p>
            <p className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-4 h-4 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                  clipRule="evenodd"
                />
              </svg>
              <b>Access responsive customer support services.</b>
            </p>
            
          </div>
        </div>
      </div>

      {/* price */}

      {/* Timeline */}

      <section>
        <div className=" text-white py-8">
          <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
            <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
              <p className="text-3xl text-black md:text-4xl font-bold leading-normal mb-2">
                Here's how it operates
              </p>
              <p className="text-sm md:text-base text-gray-700 mb-4">
                Our resume building process involves consultation, drafting,
                revisions, and finalization. It ensures a tailored, error-free
                document highlighting your skills and experience for job
                applications.
              </p>
            </div>
            <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
              <div className="container mx-auto w-full h-full">
                <div className="relative wrap overflow-hidden p-10 h-full">
                  <div
                    className="border-2-2 border-gray-700 absolute h-full border"
                    style={{
                      right: "50%",
                      border: "2px solid black",
                      borderRadius: "1%",
                    }}
                  />
                  <div
                    className="border-2-2 border-gray-700 absolute h-full border"
                    style={{
                      left: "50%",
                      border: "2px solid black",
                      borderRadius: "1%",
                    }}
                  />
                  <div className="lg:mb-8  flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-4 text-right">
                      <h4 className="mb-3 font-bold text-black text-lg md:text-2xl">
                        Consultation
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-gray-600 text-opacity-100">
                        Your designated resume writer initiates contact,
                        addressing your expectations, and gathering essential
                        information for crafting a personalized resume.
                      </p>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1  w-5/12 px-1 py-4 text-left">
                      <h4 className="mb-3 font-bold text-black text-lg md:text-2xl">
                        Drafting
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-gray-600 text-opacity-100">
                        Upon receiving the initial draft, you provide feedback
                        to the resume writer, guiding them in refining the
                        document to meet your requirements.
                      </p>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1 w-5/12 px-1 py-4 text-right">
                      <h4 className="mb-3 font-bold text-black text-lg md:text-2xl">
                        Revisions
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-gray-600 text-opacity-100">
                        The resume writer sends you the initial draft of your
                        resume for your review and input.
                      </p>
                    </div>
                  </div>
                  <div className="mb-8 flex justify-between items-center w-full right-timeline">
                    <div className="order-1 w-5/12" />
                    <div className="order-1  w-5/12 px-1 py-4">
                      <h4 className="mb-3 font-bold text-black  text-lg md:text-2xl text-left">
                        Finalization
                      </h4>
                      <p className="text-sm md:text-base leading-snug text-gray-600 text-opacity-100">
                        Upon your approval of the resume draft, we will send you
                        the final copy of your resume through your mail.
                      </p>
                    </div>
                  </div>
                </div>
                <img className="mx-auto " src={internshipImg} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Timeline */}

      
   {/* Form */}

   <div className="flex justify-center items-center place-content-center mb-20">
        <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
          Consultation Form
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-8/12 bg-white rounded-xl mx-auto shadow-xl overflow-hidden">
          <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-12 bg-no-repeat bg-cover bg-center">
            <div>
              <img
                src={ResumeForm}
                className="lg:h-[300px] h-[200px] w-full object-cover "
                alt=""
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 py-8 px-8 lg:px-6">
            <h2 className="text-3xl mb-4"> Resume Writing Services</h2>
            <p className="mb-4">Choose a service of your interest below.</p>
            <form>
              <div className="grid grid-cols-2 gap-5">
                <input
                  type="text"
                  placeholder="First Name"
                  className="border border-gray-400 py-1 px-2"
                  value={firstname}
                  onChange={(e)=>setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="border border-gray-400 py-1 px-2"
                  value={lastName}
                  onChange={(e)=>setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <input
                  type="email"
                  placeholder="Email"
                  className="border border-gray-400 py-1 px-2 w-full"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  required
                />
                  <input
                  type="number"
                  placeholder="Phone"
                  className="border border-gray-400 py-1 px-2 w-full"
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                  required
                />
              </div>
             
              <div className="mt-5">
                <Select
                  label="Select Resume Type"
                  onChange={handleResumeType}
                  animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                  }}
                  required
                >
                  <Option value="Text CV" >
                  Text CV
                  </Option>
                  <Option value="Visual CV" >
                  Visual CV
                  </Option>
                  <Option value="Infographic CV" >
                  Infographic CV
                  </Option>
                  <Option value="Cover Letter" >
                 Cover Letter
                  </Option>
                  
                </Select>
              </div>
              <div className="mt-5">
                <Select
                  label="Select Experience"
                  onChange={handleExperience}
                  animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                  }}
                  required
                >
                  <Option value="0 - 3 years" >
                  0 - 3 Years
                  </Option>
                  <Option value="3 - 8 years" >
                  3 - 8 Years
                  </Option>
                  
                  
                </Select>
              </div>
              

          
              <div className="mt-5">
                <button className="w-full bg-cyan-800 py-3 text-center text-white" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

   {/* Form */}

      {/* Way to choose us */}

      <section className="py-12  text-gray-100 sm:py-12 lg:py-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <h1 className="font-bebas-neue mb-10 uppercase text-4xl sm:text-6xl font-black flex flex-col leading-none  text-gray-800">
              Why to choose us
            </h1>
          </div>
          <div className="grid max-w-4xl lg:max-w-6xl grid-cols-1 mx-auto mt-8 text-center gap-y-4 sm:gap-x-8 sm:grid-cols-2 lg:grid-cols-3 sm:mt-12 lg:mt-20 sm:text-left">
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white shadow-md transition-transform transform hover:-translate-y-2 rounded-xl h-full">
                <div className="p-9">
                <div className="flex justify-center lg:flex lg:justify-start">
                <FiUser size={48} color="black" fontWeight={900} />
                </div>
                  
                  
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                    Expertise
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                    Our team of experienced writers understands industry trends
                    and applicant tracking systems, ensuring your resume gets
                    noticed.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
              <div className="flex justify-center lg:flex lg:justify-start">
               <FiTool size={45} color="black"/>
               </div>
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Customization
                </h3>
                <p className="mt-6 text-base text-gray-600">
                  We tailor each resume to your unique skills and career goals,
                  making you stand out.
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl h-full">
                <div className="p-9">
                <div className="flex justify-center lg:flex lg:justify-start">
                 <FiThumbsUp size={45} color="black"/>
                 </div>
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                    Quality Assurance
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                    Our rigorous review process ensures error-free, top-notch
                    resumes.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
              <div className="flex justify-center lg:flex lg:justify-start">
               <FiWatch size={45} color="black"/>
               </div>
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Timely Delivery
                </h3>
                <p className="mt-6 text-base text-gray-600">
                  We deliver on time, helping you meet crucial application
                  deadlines.
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl h-full">
                <div className="p-9">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 8L20 8"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M4 16L14 16"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={7}
                      cy={8}
                      rx={3}
                      ry={3}
                      transform="rotate(90 7 8)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={17}
                      cy={16}
                      rx={3}
                      ry={3}
                      transform="rotate(90 17 16)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Additional Services
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                  Explore a range of optional add-ons to further boost your job search success. Choose us for the best in resume writing services.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
                <div className="flex justify-center lg:flex lg:justify-start">
                <FiTruck size={45} color="black"/>
                </div>
              
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                Customer Support
                </h3>
                <p className="mt-6 text-base text-gray-600">
                Access responsive support throughout the process, addressing your queries promptly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Way to choose us */}
    </>
  );
};

export default ResumeBuilding;
