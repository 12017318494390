import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  UsersIcon,
  RocketLaunchIcon,
  PuzzlePieceIcon,
  GiftIcon,
} from "@heroicons/react/24/outline";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import {
  FcHome,
  FcServices,
  FcReading,
  FcBusinessContact,
  FcViewDetails,
  FcBriefcase,
} from "react-icons/fc";
import Signup from "./SignupLogic/Signup";
import SignupMobile from "./SignupLogic/SignupMobile";
import {
  FaUserLarge,
  FaBuilding,
  FaNoteSticky,
  FaMagnifyingGlassDollar,
} from "react-icons/fa6";

const colors = {
  blue: "bg-blue-50 text-blue-500",
  orange: "bg-orange-50 text-orange-500",
  green: "bg-green-50 text-green-500",
  "blue-gray": "bg-blue-gray-50 text-blue-gray-500",
  purple: "bg-purple-50 text-purple-500",
  teal: "bg-teal-50 text-teal-500",
  cyan: "bg-cyan-50 text-cyan-500",
  pink: "bg-pink-50 text-pink-500",
};

const navListMenuItems = [
  {
    color: "blue",
    icon: UsersIcon,
    title: "Internship Programs",
    description: "Work on live projects and gain hands on experience.",
    link: "/intellectic-internships",
  },
  {
    color: "orange",
    icon: FaUserLarge,
    title: "Personalized Websites",
    description: "Crafting Unique Online Identities Tailored Exclusively.",
    link: "/personalized-websites",
  },
  {
    color: "green",
    icon: FaBuilding,
    title: <div className="flex items-center gap-1">Business Websites</div>,
    description: "Crafting digital experiences with innovation and precision",
    link: "/business-websites",
  },
  {
    color: "blue-gray",
    icon: FaNoteSticky,
    title: "Resume Builder",
    description: "Trying for jobs! Checkout our resume building services.",
    link: "/resume-building",
  },
  {
    color: "purple",
    icon: RocketLaunchIcon,
    title: "Innovative Student Projects",
    description: "Pressed for time? We've got your project!",
    link: "/college-projects",
  },
  {
    color: "teal",
    icon: FaMagnifyingGlassDollar,
    title: "Digital Marketing",
    description: "Ready to grow? Try our digital marketing services.",
    link: "/digital-marketing",
  },
  
];

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const renderItems = navListMenuItems.map(
    ({ icon, title, description, color, link }, key) => (
      <Link to={link} key={key}>
        <MenuItem className="flex items-center gap-3 rounded-lg">
          <div className={`rounded-lg p-5 ${colors[color]}`}>
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-sm"
            >
              {title}
            </Typography>
            <Typography variant="small" color="gray" className="font-normal">
              {description}
            </Typography>
          </div>
        </MenuItem>
      </Link>
    )
  );

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-normal  text-lg">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 "
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              <FcServices className="h-[18px] w-[18px]" />
              Services
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-4 gap-y-2">{renderItems}</ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList() {
  return (
    <List className="mt-4  mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Link to={"/"} color="blue-gray" className="font-normal text-lg">
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          <FcHome className="h-[18px] w-[18px]" />
          Home
        </ListItem>
      </Link>
      <NavListMenu />
      <Link to={"/about-us"} color="blue-gray" className="font-normal text-lg">
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          <FcReading className="h-[18px] w-[18px]" />
          About Us
        </ListItem>
      </Link>
      <Link to={"/intellectic-internships"} color="blue-gray" className="font-normal text-lg">
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          <FcBriefcase className="h-[18px] w-[18px]" />
          Career
        </ListItem>
      </Link>
      <Link
        to={"/contact-us"}
        color="blue-gray"
        className="font-normal text-lg"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          <FcBusinessContact className="h-[18px] w-[18px]" />
          Contact Us
        </ListItem>
      </Link>
      <Link to={"/blogs"} color="blue-gray" className="font-normal text-lg">
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          <FcViewDetails className="h-[18px] w-[18px]" />
          Blogs
        </ListItem>
      </Link>
    </List>
  );
}

export function NavbarWithMegaMenu() {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <Navbar className="mx-auto max-w-full px-4 py-2">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Link
          to={"/"}
          variant="h6"
          className="mr-4 cursor-pointer py-1.5 lg:ml-2"
        >
          <img src={logo} className="h-10 w-40" alt="" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We design future
        </Link>
        <div className="hidden lg:block">
          <NavList />
        </div>

        <Signup />
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
        <SignupMobile />
      </Collapse>
    </Navbar>
  );
}
