import React from "react";
import personalized from "../../../assets/personalized.png";
import webDesign from "../../../assets/web_design.png";
import resume from "../../../assets/resume.png";
import seo from "../../../assets/seo.png";
import ui from "../../../assets/ui.png";
import student from "../../../assets/student_training.png"
import { Link } from "react-router-dom";

const Services = () => {
  
  return (
    <div>
      <div className="flex justify-center items-center place-content-center lg:mt-20 mt-10">
        <h1 className="text-2xl lg:mt-0 lg:text-6xl text-gray-800 lg:font-semibold font-bold mx-auto md:text-5xl ">
          Comprehensive Business Solutions
        </h1>
      </div>

      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10" />
        <div className=" max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative flex flex-col justify-center">
              <img src={personalized} className="rounded-lg" alt="" />
            </div>
          </div>
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
              Personalized Websites
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
              Your digital story, uniquely told. Our team specializes in
              tailoring websites to match your individuality. From design to
              functionality, we create a digital space that reflects you.
            </p>
            <div className="lg:w-1/2">
            <Link
              to={"/personalized-websites"}
              class="relative inline-block text-lg group"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Explore</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </Link>
            </div>
           
          </div>
        </div>
      </section>

      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 " />

        <div className="max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
            Web Development and Design
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
            Crafting captivating websites that blend creativity and technical excellence. From stunning visuals to flawless functionality, we design online platforms that resonate with your brand. Let us transform your vision into a remarkable digital reality.
            </p>
            <div className="lg:w-1/2">
            <Link
              to={"/business-websites"}
              class="relative inline-block text-lg group"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Explore</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </Link>
            </div>
          </div>
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative">
              <img src={webDesign} className="rounded-lg" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10" />
        <div className=" max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative flex flex-col justify-center">
              <img src={resume} className="rounded-lg" alt="" />
            </div>
          </div>
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
            Resume Building
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
            Crafting Impactful Resumes: Our expert team specializes in creating tailored resumes that showcase your skills, experience, and achievements. With meticulous attention to detail, we design resumes that stand out to potential employers.
            </p>
            <div className="lg:w-1/2">
            <Link
              to={"/resume-building"}
              class="relative inline-block text-lg group"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Explore</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </Link>
            </div>
          </div>
        </div>
      </section>
      
      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 " />

        <div className="max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
            Digital Marketing
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
            Elevate your online presence with our expert digital marketing services. We specialize in SEO, PPC advertising, content marketing, social media management, email campaigns, and web analytics. Our tailored strategies drive growth, increase ROI, and connect you with your target audience. Ready to boost your business in the digital world? Contact us today.
            </p>
            <div className="lg:w-1/2">
            <Link
              to={"/digital-marketing"}
              class="relative inline-block text-lg group"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Explore</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </Link>
            </div>
          </div>
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative">
              <img src={seo} className="rounded-lg" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10" />
        <div className=" max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative flex flex-col justify-center">
              <img src={ui} className="rounded-lg" alt="" />
            </div>
          </div>
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
            Student Innovation Program
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
            Our Innovative Student Project Program encompasses consultation, prototyping, and finalization phases. This program guarantees a customized, error-free project that showcases feature-rich elements, enhancing your job application prospects.
            </p>
            <div className="lg:w-1/2">
            <Link
              to={"/college-projects"}
              class="relative inline-block text-lg group"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Explore</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 " />

        <div className="max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
            Intellectic Internship Programs
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
            Explore Boundless Opportunities: Your Journey to Excellence Begins Here. Dive into Our Premium Courses and Exclusive Internships, Where Real-World Projects Await. Achieve Industry-Ready Skills and Earn Prestigious Certificates to Open Doors to Your Future Success.
            </p>
            <div className="lg:w-1/2">
            <Link
              to={"/intellectic-internships"}
              class="relative inline-block text-lg group"
            >
              <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-gray-900 rounded-lg group-hover:text-white">
                <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-gray-900 group-hover:-rotate-180 ease"></span>
                <span class="relative">Explore</span>
              </span>
              <span
                class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-gray-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                data-rounded="rounded-lg"
              ></span>
            </Link>
            </div>
          </div>
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative">
              <img src={student} className="rounded-lg" alt="" />
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};

export default Services;
