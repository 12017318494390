import React, { useState } from "react";
import staticImg from "../assets/digitalMarketing.png";
import service1 from "../assets/digitalService1.png";
import service2 from "../assets/digitalService2.png";
import service3 from "../assets/digitalService3.png";
import service4 from "../assets/digitalService4.png";
import form from "../assets/digitalService5.png";
import { Select, Option } from "@material-tailwind/react";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DigitalMarketing = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [service, setService] = useState("");
  const [requirement, setRequirement] = useState("");

  const handleService = (e) => {
    setService(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDocRef = collection(db, "digital-marketing-services");
      const newInternship = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        companyName: companyName,
        website: website,
        service: service,
        requirement: requirement,
      };
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setCompanyName("");
      setWebsite("");
      setService("");
      setRequirement("");

      await addDoc(userDocRef, newInternship);
      toast.success("Submitted Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <main className=" relative overflow-hidden">
        <div className="  flex relative z-20 items-center overflow-hidden">
          <div className="container mx-auto px-6 flex relative py-16">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
              <span className="w-20 h-2 bg-gray-800  mb-12"></span>
              <h1 className="font-bebas-neue uppercase text-4xl sm:text-6xl font-black flex flex-col leading-none  text-gray-800">
                Digital Marketing
                <span className="text-5xl sm:text-7xl">Services</span>
              </h1>
              <p className="text-sm mt-5 sm:text-base text-gray-700 ">
                Elevate your online presence with our expert digital marketing
                services. We specialize in SEO, PPC advertising, content
                marketing, social media management, email campaigns, and web
                analytics. Our tailored strategies drive growth, increase ROI,
                and connect you with your target audience. Ready to boost your
                business in the digital world? Contact us today.
              </p>
            </div>
            <div className="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
              <img src={staticImg} className=" m-auto lg:ml-10" />
            </div>
          </div>
        </div>
      </main>

      {/* Services list */}

      <div className="flex justify-center items-center place-content-center lg:mt-20 mt-10">
        <h1 className="text-3xl lg:mt-0 lg:text-6xl text-gray-800 lg:font-semibold font-bold mx-auto md:text-5xl ">
          Comprehensive Digital Marketing Solutions
        </h1>
      </div>

      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10" />
        <div className=" max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative flex flex-col justify-center">
              <img src={service1} className="rounded-lg" alt="" />
            </div>
          </div>
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
              Pay-Per-Click (PPC) Advertising
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
              Harness the power of paid advertising on platforms such as Google
              Ads and social media. Our PPC experts create and manage highly
              targeted campaigns to maximize your ROI and boost brand
              visibility.
            </p>
          </div>
        </div>
      </section>

      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10" />
        <div className=" max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
              Search Engine Optimization (SEO)
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
              Your digital story, uniquely told. Our team specializes in
              tailoring websites to match your individuality. From design to
              functionality, we create a digital space that reflects you.
            </p>
          </div>
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative flex flex-col justify-center">
              <img src={service2} className="rounded-lg" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10" />
        <div className=" max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative flex flex-col justify-center">
              <img src={service3} className="rounded-lg" alt="" />
            </div>
          </div>
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
              Social Media Management
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
              Elevate your brand's social media presence with our comprehensive
              management services. We curate content, engage with your audience,
              and run strategic paid campaigns to grow your social media
              following and drive results.
            </p>
          </div>
        </div>
      </section>

      <section>
        <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10" />
        <div className=" max-w-screen mx-auto px-4 py-10 gap-12 text-gray-600 md:px-8 xl:flex">
          <div className="space-y-5 max-w-2xl flex flex-col justify-center  mx-auto text-center xl:text-left">
            <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
              Email Marketing
            </h1>
            <p className="max-w-xl lg:text-xl mx-auto xl:mx-0">
              Unlock the potential of email marketing to nurture leads and
              maintain customer loyalty. Our targeted email campaigns deliver
              the right message to the right audience, resulting in increased
              conversions and sales.
            </p>
          </div>
          <div className="flex-1 max-w-xl mx-auto mt-14 xl:mt-0">
            <div className="relative flex flex-col justify-center">
              <img src={service4} className="rounded-lg" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* Services List */}

      {/* Form */}

      <div className="flex justify-center items-center place-content-center mb-20">
        <h1 className="text-4xl mt-10 lg:mt-0 lg:text-6xl text-gray-800 font-extrabold mx-auto md:text-5xl">
          Consultation Form
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-8/12 bg-white rounded-xl mx-auto shadow-xl overflow-hidden">
          <div className="w-full lg:w-1/2 flex flex-col items-center justify-center p-12 bg-no-repeat bg-cover bg-center">
            <div>
              <img
                src={form}
                className="lg:h-[300px] h-[200px] w-full object-cover "
                alt=""
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 py-8 px-8 lg:px-6">
            <h2 className="text-3xl mb-4"> Digital Marketing Services</h2>
            <p className="mb-4">Choose a service of your interest below.</p>
            <form>
              <div className="grid grid-cols-2 gap-5">
                <input
                  type="text"
                  placeholder="First Name"
                  className="border border-gray-400 py-1 px-2"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="border border-gray-400 py-1 px-2"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <input
                  type="email"
                  placeholder="Email"
                  className="border border-gray-400 py-1 px-2 w-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="number"
                  placeholder="Phone"
                  className="border border-gray-400 py-1 px-2 w-full"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <input
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                />
                <input
                  type="text"
                  placeholder="Website URL"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                />
              </div>
              <div className="mt-5">
                <Select
                  label="Select Service"
                  onChange={handleService}
                  animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                  }}
                >
                  <Option value="Pay-Per-Click (PPC) Advertising">
                    Pay-Per-Click (PPC) Advertising
                  </Option>
                  <Option value="Search Engine Optimization (SEO)">
                    Search Engine Optimization (SEO)
                  </Option>
                  <Option value="Social Media Management">
                    Social Media Management
                  </Option>
                  <Option value="Email Marketing">Email Marketing</Option>
                </Select>
              </div>
              <div className=" mt-5">
                <textarea
                  rows={5}
                  type="text"
                  value={requirement}
                  onChange={(e) => setRequirement(e.target.value)}
                  placeholder="How can we help? Tell us what you are looking for."
                  className="border border-gray-400 py-1 px-2 w-full"
                  required
                />
              </div>

              <div className="mt-5">
                <button
                  className="w-full bg-cyan-800 py-3 text-center text-white"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Form */}

      {/* Way to choose us */}

      <section className="py-12  text-gray-100 sm:py-12 lg:py-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-center">
            <h1 className="font-bebas-neue mb-10 uppercase text-4xl sm:text-6xl font-black flex flex-col leading-none  text-gray-800">
              Why to choose us
            </h1>
          </div>
          <div className="grid max-w-4xl lg:max-w-6xl grid-cols-1 mx-auto mt-8 text-center gap-y-4 sm:gap-x-8 sm:grid-cols-2 lg:grid-cols-3 sm:mt-12 lg:mt-20 sm:text-left">
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white shadow-md transition-transform transform hover:-translate-y-2 rounded-xl h-full">
                <div className="p-9">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 8L20 8"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M4 16L14 16"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={7}
                      cy={8}
                      rx={3}
                      ry={3}
                      transform="rotate(90 7 8)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={17}
                      cy={16}
                      rx={3}
                      ry={3}
                      transform="rotate(90 17 16)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                    Proven Track Record
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                    With a track record of success in helping businesses achieve
                    their digital marketing goals, we have the expertise and
                    experience to deliver results that matter.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
                <svg
                  className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x={13}
                    y={14}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={7}
                    y={11}
                    width={2}
                    height={6}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={11}
                    y={13}
                    width={2}
                    height={6}
                    rx={1}
                    transform="rotate(90 11 13)"
                    fill="#111827"
                  ></rect>
                  <rect
                    x={16}
                    y={12}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <path
                    d="M14 8V8C14 7.58326 14 7.37488 13.9655 7.19144C13.8455 6.5546 13.4245 6.01534 12.8358 5.74455C12.6662 5.66654 12.464 5.616 12.0597 5.51493L12 5.5C11.5388 5.3847 11.3082 5.32706 11.1171 5.233C10.5686 4.96315 10.1737 4.45731 10.0449 3.85979C10 3.65151 10 3.41382 10 2.93845V2"
                    stroke="#111827"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                  <path
                    d="M3 14C3 11.4412 3 10.1618 3.61994 9.28042C3.77954 9.05351 3.96572 8.85041 4.17372 8.6763C4.98164 8 6.15442 8 8.5 8H15.5C17.8456 8 19.0184 8 19.8263 8.6763C20.0343 8.85041 20.2205 9.05351 20.3801 9.28042C21 10.1618 21 11.4412 21 14C21 16.5588 21 17.8382 20.3801 18.7196C20.2205 18.9465 20.0343 19.1496 19.8263 19.3237C19.0184 20 17.8456 20 15.5 20H8.5C6.15442 20 4.98164 20 4.17372 19.3237C3.96572 19.1496 3.77954 18.9465 3.61994 18.7196C3 17.8382 3 16.5588 3 14Z"
                    stroke="#111827"
                    strokeWidth={2}
                  />
                </svg>
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Customized Strategies
                </h3>
                <p className="mt-6 text-base text-gray-600">
                  We don't believe in one-size-fits-all solutions. Our team
                  tailors each digital marketing strategy to your unique
                  business needs, ensuring it aligns perfectly with your goals.
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl h-full">
                <div className="p-9">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 8L20 8"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M4 16L14 16"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={7}
                      cy={8}
                      rx={3}
                      ry={3}
                      transform="rotate(90 7 8)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={17}
                      cy={16}
                      rx={3}
                      ry={3}
                      transform="rotate(90 17 16)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                    Transparent Communication
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                    We prioritize open and clear communication with our clients.
                    You'll be kept in the loop every step of the way, so you're
                    always aware of the progress and results of your campaigns.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
                <svg
                  className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x={13}
                    y={14}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={7}
                    y={11}
                    width={2}
                    height={6}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={11}
                    y={13}
                    width={2}
                    height={6}
                    rx={1}
                    transform="rotate(90 11 13)"
                    fill="#111827"
                  ></rect>
                  <rect
                    x={16}
                    y={12}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <path
                    d="M14 8V8C14 7.58326 14 7.37488 13.9655 7.19144C13.8455 6.5546 13.4245 6.01534 12.8358 5.74455C12.6662 5.66654 12.464 5.616 12.0597 5.51493L12 5.5C11.5388 5.3847 11.3082 5.32706 11.1171 5.233C10.5686 4.96315 10.1737 4.45731 10.0449 3.85979C10 3.65151 10 3.41382 10 2.93845V2"
                    stroke="#111827"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                  <path
                    d="M3 14C3 11.4412 3 10.1618 3.61994 9.28042C3.77954 9.05351 3.96572 8.85041 4.17372 8.6763C4.98164 8 6.15442 8 8.5 8H15.5C17.8456 8 19.0184 8 19.8263 8.6763C20.0343 8.85041 20.2205 9.05351 20.3801 9.28042C21 10.1618 21 11.4412 21 14C21 16.5588 21 17.8382 20.3801 18.7196C20.2205 18.9465 20.0343 19.1496 19.8263 19.3237C19.0184 20 17.8456 20 15.5 20H8.5C6.15442 20 4.98164 20 4.17372 19.3237C3.96572 19.1496 3.77954 18.9465 3.61994 18.7196C3 17.8382 3 16.5588 3 14Z"
                    stroke="#111827"
                    strokeWidth={2}
                  />
                </svg>
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Continuous Optimization
                </h3>
                <p className="mt-6 text-base text-gray-600">
                  Digital marketing is an ever-evolving field. We stay at the
                  forefront of industry trends and continually optimize your
                  strategies to keep you ahead of the competition.
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1">
                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <div className="relative overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl h-full">
                <div className="p-9">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 8L20 8"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <path
                      d="M4 16L14 16"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={7}
                      cy={8}
                      rx={3}
                      ry={3}
                      transform="rotate(90 7 8)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx={17}
                      cy={16}
                      rx={3}
                      ry={3}
                      transform="rotate(90 17 16)"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                    Data-Driven Insights
                  </h3>
                  <p className="mt-6 text-base text-gray-600">
                    Our approach is rooted in data and analytics. We provide you
                    with in-depth insights and reports that guide
                    decision-making and help you understand the real impact of
                    your digital marketing efforts.
                  </p>
                </div>
              </div>
            </div>
            <div className="overflow-hidden bg-white transition-transform transform hover:-translate-y-2 shadow-md rounded-xl">
              <div className="p-9">
                <svg
                  className="w-12 h-12 mx-auto text-gray-400 sm:mx-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x={13}
                    y={14}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={7}
                    y={11}
                    width={2}
                    height={6}
                    rx={1}
                    fill="#111827"
                  />
                  <rect
                    x={11}
                    y={13}
                    width={2}
                    height={6}
                    rx={1}
                    transform="rotate(90 11 13)"
                    fill="#111827"
                  ></rect>
                  <rect
                    x={16}
                    y={12}
                    width={2}
                    height={2}
                    rx={1}
                    fill="#111827"
                  />
                  <path
                    d="M14 8V8C14 7.58326 14 7.37488 13.9655 7.19144C13.8455 6.5546 13.4245 6.01534 12.8358 5.74455C12.6662 5.66654 12.464 5.616 12.0597 5.51493L12 5.5C11.5388 5.3847 11.3082 5.32706 11.1171 5.233C10.5686 4.96315 10.1737 4.45731 10.0449 3.85979C10 3.65151 10 3.41382 10 2.93845V2"
                    stroke="#111827"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                  <path
                    d="M3 14C3 11.4412 3 10.1618 3.61994 9.28042C3.77954 9.05351 3.96572 8.85041 4.17372 8.6763C4.98164 8 6.15442 8 8.5 8H15.5C17.8456 8 19.0184 8 19.8263 8.6763C20.0343 8.85041 20.2205 9.05351 20.3801 9.28042C21 10.1618 21 11.4412 21 14C21 16.5588 21 17.8382 20.3801 18.7196C20.2205 18.9465 20.0343 19.1496 19.8263 19.3237C19.0184 20 17.8456 20 15.5 20H8.5C6.15442 20 4.98164 20 4.17372 19.3237C3.96572 19.1496 3.77954 18.9465 3.61994 18.7196C3 17.8382 3 16.5588 3 14Z"
                    stroke="#111827"
                    strokeWidth={2}
                  />
                </svg>
                <h3 className="mt-6 text-2xl font-bold text-gray-900 sm:mt-10">
                  Client-Centric Approach
                </h3>
                <p className="mt-6 text-base text-gray-600">
                  Your success is our success. We prioritize your business
                  objectives and work tirelessly to deliver the best possible
                  results, ensuring your satisfaction is at the forefront of
                  everything we do.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Way to choose us */}
    </>
  );
};

export default DigitalMarketing;
