import React from 'react'
import Hero from '../components/AboutPage/Hero'

const AboutUs = () => {
  return (
    <div>
        <Hero/>
    </div>
  )
}

export default AboutUs