import React from 'react'
import HomeBlog from '../components/Blogs/HomeBlog'
import ComingSoon from '../components/Blogs/ComingSoon'
const Blog = () => {
  return (
    <div>
        {/* <HomeBlog/> */}
        <ComingSoon/>

    </div>
  )
}

export default Blog