import React from 'react'
import { Link } from 'react-router-dom'

const ComingSoon = () => {
  return (
    <div>
         <div class="min-h-screen flex flex-col justify-center items-center">
    <img src="https://www.svgrepo.com/show/426192/cogs-settings.svg" alt="Logo" class="mb-8 h-40"/>
    <h1 class="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-gray-900 mb-4">This webpage is under maintenance</h1>
    <p class="text-center text-gray-700 text-lg md:text-xl lg:text-2xl mb-8">We're working hard to improve the user experience. Stay tuned!</p>
    <div class="flex space-x-4">
      <Link to="/" class="bg-gray-800 hover:bg-gray-700 text-white font-bold py-3 px-6 rounded">Home</Link>
      <Link to="/blogs" class="border-2 border-gray-800 text-black font-bold py-3 px-6 rounded">Reload</Link>
    </div>
  </div>
    </div>
  )
}

export default ComingSoon